import React, {
  useEffect,
} from "react";

const pagesWithoutWeglot = [
  '/admin/my-calcumate/new'
];

export default ({children}) => {

  useEffect(() => {
    if (pagesWithoutWeglot.includes(window.location.pathname))
      document.querySelector('#root').classList.add('integration-layout');
    return () => document.querySelector('#root').classList.remove('integration-layout');
  }, []);

  return <div>{children}</div>;
}
