import countries from "./countries.json";
import { freeItems } from "../helpers/validation";
import CrownCircle from "../assets/img/crown-circle.svg";
import Rainbow from "../assets/img/rainbow.svg";
import Start from "../assets/img/star.svg";
import ContactUs from "../assets/img/contact-us.svg";
import {
  usdPriceRangeM,
  audPriceRangeM,
  eurPriceRangeM,
  gbpPriceRangeM,
  usdPriceRangeY,
  audPriceRangeY,
  eurPriceRangeY,
  gbpPriceRangeY,
  usdContainerPriceRangeM,
  audContainerPriceRangeM,
  eurContainerPriceRangeM,
  gbpContainerPriceRangeM,
  usdContainerPriceRangeY,
  audContainerPriceRangeY,
  eurContainerPriceRangeY,
  gbpContainerPriceRangeY,
  usdMoversPriceRangeM,
  audMoversPriceRangeM,
  eurMoversPriceRangeM,
  gbpMoversPriceRangeM,
  usdMoversPriceRangeY,
  audMoversPriceRangeY,
  eurMoversPriceRangeY,
  gbpMoversPriceRangeY,
  usdPortablePriceRangeM,
  audPortablePriceRangeM,
  eurPortablePriceRangeM,
  gbpPortablePriceRangeM,
  usdPortablePriceRangeY,
  audPortablePriceRangeY,
  eurPortablePriceRangeY,
  gbpPortablePriceRangeY,
  usdKiosksPriceRangeM,
  audKiosksPriceRangeM,
  eurKiosksPriceRangeM,
  gbpKiosksPriceRangeM,
  usdKiosksPriceRangeY,
  audKiosksPriceRangeY,
  eurKiosksPriceRangeY,
  gbpKiosksPriceRangeY,
} from "./prices";

export const defaultSorting = {
  Bedroom: {
    "WARDROBE-TWO-DOOR": 1,
    "WARDROBE-ONE-DOOR": 2,
    COT: 3,
    "BEDSIDETABLE-BEDROOM-5": 4,
    "DESK-BEDROOM-8": 5,
    "LAMP-DESK": 6,
    "LAMP-FLOOR": 7,
    "DRAWERS-BEDROOM-6": 8,
    "QUEENKINGMATTRESS-BEDROOM-2": 9,
    "BEDBASEENSEMBLE-BEDROOM-3": 10,
    "BEDBASEFRAME-BEDROOM-4": 11,
  },
  Other: {
    "MEDIUMBOX-OTHER-30": 1,
    "LARGEBOX-OTHER-31": 2,
    "STORAGE-TOTE": 3,
    LADDER: 4,
    LAWNMOWER: 5,
    TOOLBOX: 6,
    BIKE: 7,
    "air-conditioner": 8,
    "ARCADE-OTHER-35": 9,
    "SURFBOARD-OTHER-36": 10,
    "EXERCISE-BIKE": 11,
    PAINT: 12,
  },
};

export const getDefaultSelfStorageUnits = () => [
  {
    ID: 0,
    unitName: ["2m x 2m x 3m Unit", "5ft x 5ft x 10ft Unit"],
    unitWidth: ["2", "5"],
    unitHeight: ["3", "10"],
    unitDepth: ["2", "5"],
  },
  {
    ID: 1,
    unitName: ["3m x 3m x 3m Unit", "5ft x 10ft x 10ft Unit"],
    unitWidth: ["3", "5"],
    unitHeight: ["3", "10"],
    unitDepth: ["3", "10"],
  },
  {
    ID: 2,
    unitName: ["4m x 4m x 3m Unit", "10ft x 10ft x 10ft Unit"],
    unitWidth: ["4", "10"],
    unitHeight: ["3", "10"],
    unitDepth: ["4", "10"],
  },
  {
    ID: 3,
    unitName: ["5m x 5m x 3m Unit", "10ft x 15ft x 10ft Unit"],
    unitWidth: ["5", "10"],
    unitHeight: ["3", "10"],
    unitDepth: ["5", "15"],
  },
  {
    ID: 4,
    unitName: ["10m x 5m x 3m Unit", "10ft x 20ft x 10ft Unit"],
    unitWidth: ["10", "10"],
    unitHeight: ["3", "10"],
    unitDepth: ["5", "20"],
  },
];
export const serviceMenu = [
  "container-storage",
  "mobile-storage",
  "movers",
  "self-storage",
];
export const whyItWorks = {
  home: {
    mainHeader: "Why Calcumate Works",
    subHeader:
      "Boost your bookings, improve your SEO and save time with sales calls…",
    slides: [
      {
        image: "Point-2.svg",
        header: "Easy to pick up",
        subHeader:
          "Calcumate’s visual style makes it easy for potential customers to understand and is proven to boost online engagement. The calculator is also fully mobile-optimised!",
      },
      {
        image: "Point-3.svg",
        header: "Simple to assemble",
        subHeader: `With our calculator builder, you can add all your unit sizes, branding, items & more. You can also see a preview of your calculator in real-time, to make sure you’re happy at every step.`,
      },
      {
        image: "Point-1.svg",
        header: "Started in the industry",
        subHeader: `In 2019, a storage operator created Calcumate to improve their website’s performance. After seeing online bookings increase 26% in 3 months, the calculator is now a must-have sales tool for any storage company.`,
      },
      {
        image: "Point-4.svg",
        header: "Plays nice with others",
        subHeader: `Up and running in under 5 minutes, adding Calcumate to your website is a simple copy & paste job that requires no technical expertise. We can also help with any onboarding needs.`,
      },
    ],
  },
  removals: {
    mainHeader: "Revolutionising removals",
    subHeader:
      "Calcumate is designed to fit easily onto your website and drive more revenue.",
    slides: [
      {
        image: "removals-1.svg",
        header: "Simple solutions",
        subHeader:
          "Recommend the right amount of space for your customers’ needs. Trusted and accurate, Calcumate means no more guesswork or frustrations on loading day.",
      },
      {
        image: "removals-2.svg",
        header: "Trusted by global movers",
        subHeader:
          "Easytruck operates over 700 Pods and an award-winning moving team in the heart of Dubai. They use Calcumate on their website to help both movers and storers.",
      },
      {
        image: "removals-3.svg",
        header: "Phone quotes?",
        subHeader:
          "Calcumate is your Sales teams’ new best friend. Once the caller has listed what they’re looking to shift, your team member can use our calculator to quote the job in seconds.",
      },
      {
        image: "removals-4.svg",
        header: "Plays nice with others",
        subHeader:
          "Calcumate can be plugged onto any website, generating leads and securing bookings - all inside 10 minutes.",
      },
    ],
  },
};
export const removalsPlans = [
  {
    link: "",
    color: "secondary",
    name: "Essential",
    features: [
      { checked: true, text: "Free forever" },
      { checked: true, text: "Your truck/van dimensions" },
      { checked: true, text: "A set list of 40 household items" },
      { checked: false, text: "Calcumate branding" },
      { checked: false, text: "No list of what they need moving" },
    ],
  },
  {
    link: "",
    color: "primary",
    name: "Starter",
    features: [
      { checked: true, text: "Free setup & 30-day trial" },
      { checked: true, text: "Your truck/van dimensions" },
      { checked: true, text: "Choose from over 90 items" },
      { checked: true, text: "Your branding, logo & colours" },
      { checked: true, text: "Lead details emailed to you" },
    ],
  },
];
const defaultUnits = {
  "self-storage": [
    {
      ID: 0,
      unitName: ["Locker", "Locker"],
      unitWidth: ["1.5", "5"],
      unitHeight: ["1.5", "5"],
      unitDepth: ["1.2", "4"],
    },
    {
      ID: 1,
      unitName: ["3m x 3m", "25 sq ft"],
      unitWidth: ["3", "5"],
      unitHeight: ["2.4", "8"],
      unitDepth: ["3", "5"],
    },
    {
      ID: 2,
      unitName: ["5m x 5m", "50 sq ft"],
      unitWidth: ["5", "10"],
      unitHeight: ["2.4", "8"],
      unitDepth: ["5", "5"],
    },
    {
      ID: 3,
      unitName: ["6m x 5m", "100 sq ft"],
      unitWidth: ["6", "10"],
      unitHeight: ["3", "8"],
      unitDepth: ["5", "10"],
    },
  ],
  "mobile-storage": [
    {
      ID: 0,
      unitName: ["Storage Unit(s)", "Storage Unit(s)"],
      unitWidth: ["1.5", "5"],
      unitHeight: ["2.2", "7"],
      unitDepth: ["2.4", "8"],
    },
  ],
  "container-storage": [
    {
      ID: 0,
      unitName: ["Medium Container", "20 ft Container"],
      unitWidth: ["2.4", "8"],
      unitHeight: ["2.4", "8"],
      unitDepth: ["6", "20"],
    },
    {
      ID: 0,
      unitName: ["Large Container", "40 ft Container"],
      unitWidth: ["2.4", "8"],
      unitHeight: ["2.4", "8"],
      unitDepth: ["12", "40"],
    },
  ],
  "removals-storage": [
    {
      ID: 0,
      unitName: ["Medium Truck", "Medium Truck"],
      unitWidth: ["1.8", "6"],
      unitHeight: ["1.4", "10"],
      unitDepth: ["3.2", "6"],
    },
    {
      ID: 0,
      unitName: ["Large Truck", "Large Truck"],
      unitWidth: ["2.3", "8"],
      unitHeight: ["2.1", "13"],
      unitDepth: ["4", "7"],
    },
  ],
  "portable-storage": [
    {
      ID: 0,
      unitName: ["Storage Unit(s)", "Storage Unit(s)"],
      unitWidth: ["1.5", "5"],
      unitHeight: ["2.2", "7"],
      unitDepth: ["2.4", "8"],
    },
  ],
};

const allowedBasic = [
  "integrationActiveLanguage",
  "integrationMeasurement",
  "integrationStorageType",
  "integrationDomain",
  "unitName",
  "unitWidth",
  "unitHeight",
  "unitDepth",
  "unitReserve",
  "itemLibraryCustomNames",
  "integrationName",
  "weightLimit",
  "integrationCopy",
  "integrationUnitImage",
];

export const designFeatures = [
  "integrationActiveFont",
  "integrationPrimaryText",
  "integrationSecondaryText",
  "integrationLogo",
  "integrationPrimaryColour",
  "integrationSecondaryColour",
  "integrationUnitColor",
  "integrationGradientOne",
  "integrationGradientTwo",
  "integrationButtonColour",
  "integrationItemTextColour",
  "integrationButtonTextColour",
  "integrationActiveBgStyle",
  "integrationPrimaryTextColor",
];

const allowedCore = [
  ...allowedBasic,
  ...designFeatures,
  "integrationCreateCategory",
  "hideResultUnitDetails",
  "showResultUnitDetails",
  "integrationCtaURL",
  "integrationCTAType",
  "integrationCTALabel",
  "integrationCTATarget",
  "integrationPresetAdvanced",
  "preName",
  "presetChange",
  "presetQuantity",
  "optionAdvancedSettings",
  "editCategory",
  "removeCategory",
  "sortingCategory",
  "managePresets",
  "integrationDisableMultipleResults",
  "catName",
  "additional",
  "showUnitsNote",
  "integrationDisplaySearch",
  "integrationCTARedirection",
  "integrationShowItemWeight",
  "integrationDisplaySearch",
  "integrationAddCustomItems",
  "integrationItemsListMode",
  "integrationDomain2",
  "integrationAmountMultipleUnit",
  "integrationAllowCopy",
  "integrationPresetsLabel",
  "integrationPresetMenuPosition",
  "integrationSelectedItemsIntoURL",
  "integrationHideIsometricGrid",
  "integrationCategoryButtonBorderColor",
  "integrationIncUsedVolume",
  "integrationIncVolume",
  "includeDate",
  "includeSessionId",
  "includeRecommendedUnit",
  "includeTime",
  "includeItems",
  "includeUrl",
  "includeCalculator",
  "includeDynamicForm",
  "isEnabledNearestFacility",
];

const allowedGenerator = [
  ...allowedCore,
  "integrationAllowAnimationControl",
  "showLoadingPlanButton",
  "integrationLoadDetailsActive",
  "integrationIncQty",
  "integrationIncID",
  "itemLibrarySizes",
  "generationReports",
  "loadGenerationForm",
  "loadingCustomerEmail",
  "loadingPlanFields",
  "integrationLoadDetailsEmail",
  "integrationUrl",
  "integrationLoadDetailsEmails",
  "integrationLoadingCustomerEmails",
  "integrationPresetColor",
  "integrationPresetBackground",
  "integrationCtaShouldIncludeUnitQuantity",
];

export const integrationsAmount = {
  Essential: 1,
  Limited: 1,
  Basic: 3,
  Core: 3,
  "Core (Custom)": 3,
  Generator: 10,
  Enterprise: 100,
  Starter: 5,
  Professional: 15,
  Custom: 100,
  Storagely: 100,
  Complete: 10,
  "Complete-container": 10,
  "Complete-movers": 10,
  "Complete-portable": 10,
  "Complete-kiosks": 10,
  "Contact-us": 100,
};

export const defaultReportConfig = {
  includeDate: true,
  includeSessionId: true,
  includeRecommendedUnit: true,
  includeTime: true,
  includeItems: true,
  includeUrl: true,
  includeCalculator: true,
  includeDynamicForm: false,
};

const allowedEnterprise = [...allowedGenerator];

const allowedCustom = [...allowedEnterprise];

const allowedStoragely = [...allowedGenerator, "integrationHideCopyright"];

export const mapPlansLimitations = {
  Essential: allowedBasic,
  Limited: allowedBasic,
  Complete: allowedGenerator,
  "Complete-container": allowedGenerator,
  "Complete-movers": allowedGenerator,
  "Complete-portable": allowedGenerator,
  "Complete-kiosks": allowedGenerator,
  Core: allowedCore,
  "Core (Custom)": allowedCore,
  Generator: allowedGenerator,
  Enterprise: allowedEnterprise,
  Basic: allowedBasic,
  Starter: allowedGenerator,
  Professional: allowedGenerator,
  Custom: allowedCustom,
  Storagely: allowedStoragely,
  "Contact-us": allowedEnterprise,
};

export const defaultUnitsHandler = (type) =>
  defaultUnits[type] ? defaultUnits[type] : defaultUnits["self-storage"];

export const getDefaultMobileStorageUnit = () => ({
  ID: 0,
  unitName: ["Storage Unit(s)", "Storage Unit(s)"],
  unitWidth: ["1.5", "5"],
  unitHeight: ["2.2", "7"],
  unitDepth: ["2.4", "8"],
});

const completeFeatureList = [
  {
    name: "Add on unlimited facilities",
    tooltip:
      "Select the number of facilities your company operates to create a dedicated calculator for each",
  },
  {
    name: "One calculator per facility",
    tooltip:
      "Customize a calculator for each facility to reflect the specific unit sizes available at each location",
  },
  {
    name: "Support from our friendly and knowledgeable team",
    tooltip:
      "Access assistance from our dedicated support team whenever you need it",
  },
  {
    name: "130+ inventory items",
    tooltip:
      "Offer a vast selection of over 130 inventory items to meet diverse customer needs",
  },
  {
    name: "Fully responsive design for use across desktop, mobile and tablet",
    tooltip:
      "Enjoy a seamless experience on any device, whether it’s a desktop, tablet, or smartphone",
  },
  {
    name: "Any spoken language",
    tooltip:
      "Access and create the calculator in any language, making it suitable for global teams and diverse user bases",
  },
  {
    name: "Upload your sizes",
    tooltip:
      "Easily import predefined unit sizes to quickly configure the calculator",
  },
  {
    name: "Website embeddable",
    tooltip:
      "Easily integrate our service with your existing website using our provided code snippet",
  },
  {
    name:
      "Integration to your website's online quote / rental / booking flow via URL GET Parameters, Javascript Event and Browser Local Storage",
    tooltip:
      "Streamline user interactions by integrating with your existing online processes effortlessly, when they click the call-to-action button (i.e. contact form or booking page)",
  },
  {
    name: "30-day free trial when purchased online",
    tooltip:
      "Experience all features risk-free with a 30-day trial when you purchase online",
  },
  {
    name: "Custom Branding",
    tooltip: "Customize the platform with your brand’s identity",
  },
  {
    name: "Add your company logo to your storage unit",
    tooltip: `Personalize your units by uploading your company's logo`,
  },
  {
    name: "Request lead details from the user",
    tooltip:
      "Prompt users to enter their contact details before accessing specific features or content",
  },
  {
    name: "Usage reporting for each facility",
    tooltip:
      "Access detailed reports on usage statistics for each facility to analyze and optimize performance",
  },
  {
    name: "Create category items",
    tooltip:
      "Group items into up to six common categories, such as bedroom, living room, kitchen, and bathroom",
  },
  {
    name:
      "Display up to 5 preset categories for quick access to relevant options (e.g., 1 Bedroom, 2 Bedroom, etc.)",
    tooltip:
      "Presets allow to choose from category lists instead of adding items one by one, enabling to quickly calculate the space needed",
  },
  {
    name: "Allow the user to bookmark their result",
    tooltip:
      "This will ask for the user’s name, email and phone number, before providing them with a link to the calculator that has the items they added saved. Good for saving for later or sending to friends",
  },
  {
    name: "Set up multiple calculators fast",
    tooltip: "Quickly create multiple calculators with the create copy button",
  },
  {
    name: "Recommend multiple unit sizes",
    tooltip: `We’ll recommend the smallest unit size for their items, but you can also display the next size(s) up`,
  },
  {
    name: "Enable/disable available units in real time via Javascript Event",
    tooltip:
      "Control unit availability dynamically in real-time for a better user experience.",
  },
  {
    name: "Customize your items name/size/weight",
    tooltip:
      "Tailor the specifics of your inventory items to better match your offerings",
  },
  {
    name: "Additional Measurements and Dials Features",
    tooltip:
      "Provide users with detailed item metrics such as unit volume, space used, total weight, and furniture dimensions, alongside interactive controls for playing, pausing, re-playing stacking animations, and copying selected items",
  },
];

const InitState = {
  isLoggedIn: false,
  showInactiveMessage: false,
  currency: "USD",
  defaultCurrency: "USD",
  availableCurrencies: [
    { currency: "USD", sign: "$" },
    { currency: "AUD", sign: "$" },
    { currency: "GBP", sign: "£" },
    { currency: "EUR", sign: "€" },
  ],
  signUpStepperStep: 0,
  signUpStepperSteps: ["Account", "Confirmation"],
  signedInUser: {
    token: "",
    cukey: "",
    data: {},
  },
  registrationCompleted: false,
  submittingUserData: false,
  fetching: false,
  reportConfig: defaultReportConfig,
  userData: {
    email: "",
    firstName: "",
    lastName: "",
    phone: "",
    company: "",
    password: "",
    passwordInvalid: false,
    emailInvalid: false,
    passwordValidated: false,
    codeValidated: false,
    emailValidated: false,
    confirmationCode: "",
    confirmationCodeArray: ["", "", "", ""],
    billing: {
      creditCard: {
        number: "",
        cvv: "",
        expiry: "",
        valid: false,
      },
      paymentMethod: null,
      country: "AU",
      firstName: "",
      lastName: "",
      address: "",
      company: "",
      invalidCard: false,
    },
  },
  accountCurrentCountryList: countries,
  pages: {
    home: {
      title: "Homepage",
    },
    signup: {
      title: "Sign up",
    },
    login: {
      title: "Login",
    },
    passrequest: {
      title: "Password request",
    },
    contactUs: {
      title: "Contact us",
    },
  },
  cmPlanPayRec: false,
  cmPricingWidgetVisible: false,
  recommendedPlan: "Professional",
  highlightedPlan: 3,
  currentPlan: "Complete",
  currentPlanLabel: "Complete",
  currentPlanPrice: 0,
  currentPlanPriceRec: "/mo",
  serviceNumber: 1,
  service: "container-storage",
  signUpPlanUrl: "/sign-up",
  upDownGradePlanUrl: "/admin/my-account/my-subscription/modify",
  cancelPlanUrl: "/admin/my-account/my-subscription/cancel",
  calcumatePlans: [
    {
      sidUSD: [
        process.env.REACT_APP_STRIPE_BASIC_PRICE_MONTHLY,
        process.env.REACT_APP_STRIPE_BASIC_PRICE_MONTHLY,
      ],
      sidAUD: [
        process.env.REACT_APP_STRIPE_BASIC_PRICE_MONTHLY,
        process.env.REACT_APP_STRIPE_BASIC_PRICE_MONTHLY,
      ],
      sidGBP: [
        process.env.REACT_APP_STRIPE_BASIC_PRICE_MONTHLY,
        process.env.REACT_APP_STRIPE_BASIC_PRICE_MONTHLY,
      ],
      sidEUR: [
        process.env.REACT_APP_STRIPE_BASIC_PRICE_MONTHLY,
        process.env.REACT_APP_STRIPE_BASIC_PRICE_MONTHLY,
      ],
      isRecommended: false,
      theAction: "/sign-up?plan=limited",
      thePlan: "Limited",
      thePlanLabel: "Limited",
      buttonLabel: "free",
      showSign: true,
      USD: [0, 0],
      AUD: [0, 0],
      GBP: [0, 0],
      EUR: [0, 0],
      theDiscount: {
        yr: "",
        mo: "",
      },
      theFeatureList: [
        {
          name: "Maximum of one facility",
          tooltip:
            "Designed for companies that operate only one storage facility",
        },
        {
          name: "Maximum of one calculator",
          tooltip: "Allows to create only one calculator",
        },
        {
          name: "Self-support only",
          tooltip:
            "Access resources and guides for self-service support; assistance is not available directly from our team",
        },
        {
          name: freeItems.length + " common furniture items",
          tooltip: `Limited to ${freeItems.length} essential furniture items to meet your customers' needs`,
        },
        {
          name: `Fully responsive design for use across desktop, mobile and tablet`,
          tooltip:
            "Enjoy a seamless experience on any device, whether it’s a desktop, tablet, or smartphone",
        },
        {
          name: "Any spoken language",
          tooltip:
            "Access and create the calculator in any language, making it suitable for global teams and diverse user bases",
        },
        {
          name: "Upload your sizes",
          tooltip:
            "Easily import predefined unit sizes to quickly configure the calculator",
        },
        {
          name: "Website embeddable",
          tooltip:
            "Easily integrate our service with your existing website using our provided code snippet",
        },
      ],
      theDisabledFeatureList: [
        {
          name:
            "Integration to your website's online quote / rental / booking flow via URL GET Parameters, Javascript Event and Browser Local Storage",
          tooltip: "",
        },
        {
          name: "30-day free trial when purchased online",
          tooltip: "",
        },
        { name: "Custom Branding", tooltip: "" },
        { name: "Add your company logo to your storage unit", tooltip: "" },
        { name: "Request lead details from the user", tooltip: "" },
        { name: "Usage reporting for each facility", tooltip: "" },
        { name: "Create category items", tooltip: "" },
        {
          name:
            "Display up to 5 preset categories for quick access to relevant options (e.g., 1 Bedroom, 2 Bedroom, etc.)",
          tooltip: "",
        },
        { name: "Allow the user to bookmark their result", tooltip: "" },
        { name: "Set up multiple calculators fast", tooltip: "" },
        { name: "Recommend multiple unit sizes", tooltip: "" },
        {
          name:
            "Enable/disable available units in real time via Javascript Event",
          tooltip: "",
        },
        { name: "Customize your items name/size/weight", tooltip: "" },
        { name: "Additional Measurements and Dials Features", tooltip: "" },
      ],
      theFeatureListPricing: ["Limited features."],
      theme: "white",
      icon: Rainbow,
      classes: "limited",
      unitLabel: "Facility",
    },
    {
      sidUSD: [
        process.env.REACT_APP_STRIPE_FALITY_PRICE_YEARLY_USD,
        process.env.REACT_APP_STRIPE_FALITY_PRICE_MONTHLY_USD,
      ],
      sidAUD: [
        process.env.REACT_APP_STRIPE_FALITY_PRICE_YEARLY_AUD,
        process.env.REACT_APP_STRIPE_FALITY_PRICE_MONTHLY_AUD,
      ],
      sidGBP: [
        process.env.REACT_APP_STRIPE_FALITY_PRICE_YEARLY_GBP,
        process.env.REACT_APP_STRIPE_FALITY_PRICE_MONTHLY_GBP,
      ],
      sidEUR: [
        process.env.REACT_APP_STRIPE_FALITY_PRICE_YEARLY_EUR,
        process.env.REACT_APP_STRIPE_FALITY_PRICE_MONTHLY_EUR,
      ],
      showTotalLabel: true,
      isRecommended: true,
      theAction: "/sign-up/",
      thePlan: "Complete",
      thePlanLabel: "Complete",
      showSign: true,
      showCounter: true,
      USD: [usdPriceRangeY, usdPriceRangeM],
      AUD: [audPriceRangeY, audPriceRangeM],
      GBP: [gbpPriceRangeY, gbpPriceRangeM],
      EUR: [eurPriceRangeY, eurPriceRangeM],
      theDiscount: {
        yr: "10% discount applied",
        mo: "Get 10% off on yearly plans",
      },
      theFeatureList: completeFeatureList,
      theFeatureListPricing: ["All features."],
      theme: "#075695",
      popular: true,
      icon: Start,
      service: "self-storage",
      unitLabel: "Facility",
    },
    {
      service: "container-storage",
      sidUSD: [
        process.env.REACT_APP_STRIPE_FALITY_PRICE_CONTAINER_YEARLY_USD,
        process.env.REACT_APP_STRIPE_FALITY_PRICE_CONTAINER_MONTHLY_USD,
      ],
      sidAUD: [
        process.env.REACT_APP_STRIPE_FALITY_PRICE_CONTAINER_YEARLY_AUD,
        process.env.REACT_APP_STRIPE_FALITY_PRICE_CONTAINER_MONTHLY_AUD,
      ],
      sidGBP: [
        process.env.REACT_APP_STRIPE_FALITY_PRICE_CONTAINER_YEARLY_GBP,
        process.env.REACT_APP_STRIPE_FALITY_PRICE_CONTAINER_MONTHLY_GBP,
      ],
      sidEUR: [
        process.env.REACT_APP_STRIPE_FALITY_PRICE_CONTAINER_YEARLY_EUR,
        process.env.REACT_APP_STRIPE_FALITY_PRICE_CONTAINER_MONTHLY_EUR,
      ],
      showTotalLabel: true,
      isRecommended: true,
      theAction: "/sign-up/",
      thePlan: "Complete-container",
      thePlanLabel: "Complete",
      showSign: true,
      showCounter: true,
      USD: [usdContainerPriceRangeY, usdContainerPriceRangeM],
      AUD: [audContainerPriceRangeY, audContainerPriceRangeM],
      GBP: [gbpContainerPriceRangeY, gbpContainerPriceRangeM],
      EUR: [eurContainerPriceRangeY, eurContainerPriceRangeM],
      theDiscount: {
        yr: "10% discount applied",
        mo: "Get 10% off on yearly plans",
      },
      theFeatureList: completeFeatureList,
      theDisabledFeatureList: [],
      theFeatureListPricing: ["All features."],
      theme: "#075695",
      popular: true,
      icon: Start,
      unitLabel: "Facility",
    },
    {
      service: "movers",
      sidUSD: [
        process.env.REACT_APP_STRIPE_FALITY_PRICE_MOVERS_YEARLY_USD,
        process.env.REACT_APP_STRIPE_FALITY_PRICE_MOVERS_MONTHLY_USD,
      ],
      sidAUD: [
        process.env.REACT_APP_STRIPE_FALITY_PRICE_MOVERS_YEARLY_AUD,
        process.env.REACT_APP_STRIPE_FALITY_PRICE_MOVERS_MONTHLY_AUD,
      ],
      sidGBP: [
        process.env.REACT_APP_STRIPE_FALITY_PRICE_MOVERS_YEARLY_GBP,
        process.env.REACT_APP_STRIPE_FALITY_PRICE_MOVERS_MONTHLY_GBP,
      ],
      sidEUR: [
        process.env.REACT_APP_STRIPE_FALITY_PRICE_MOVERS_YEARLY_EUR,
        process.env.REACT_APP_STRIPE_FALITY_PRICE_MOVERS_MONTHLY_EUR,
      ],
      USD: [usdMoversPriceRangeY, usdMoversPriceRangeM],
      AUD: [audMoversPriceRangeY, audMoversPriceRangeM],
      GBP: [gbpMoversPriceRangeY, gbpMoversPriceRangeM],
      EUR: [eurMoversPriceRangeY, eurMoversPriceRangeM],
      theDiscount: {
        yr: "10% discount applied",
        mo: "Get 10% off on yearly plans",
      },
      theFeatureList: completeFeatureList,
      theFeatureListPricing: ["All features."],
      showTotalLabel: true,
      isRecommended: true,
      theAction: "/sign-up/",
      thePlan: "Complete-movers",
      thePlanLabel: "Complete",
      showSign: true,
      showCounter: true,
      theme: "#075695",
      popular: true,
      icon: Start,
      unitLabel: "Facility",
    },
    {
      service: "portable-storage",
      sidUSD: [
        process.env.REACT_APP_STRIPE_FALITY_PRICE_PORTABLE_YEARLY_USD,
        process.env.REACT_APP_STRIPE_FALITY_PRICE_PORTABLE_MONTHLY_USD,
      ],
      sidAUD: [
        process.env.REACT_APP_STRIPE_FALITY_PRICE_PORTABLE_YEARLY_AUD,
        process.env.REACT_APP_STRIPE_FALITY_PRICE_PORTABLE_MONTHLY_AUD,
      ],
      sidGBP: [
        process.env.REACT_APP_STRIPE_FALITY_PRICE_PORTABLE_YEARLY_GBP,
        process.env.REACT_APP_STRIPE_FALITY_PRICE_PORTABLE_MONTHLY_GBP,
      ],
      sidEUR: [
        process.env.REACT_APP_STRIPE_FALITY_PRICE_PORTABLE_YEARLY_EUR,
        process.env.REACT_APP_STRIPE_FALITY_PRICE_PORTABLE_MONTHLY_EUR,
      ],
      USD: [usdPortablePriceRangeY, usdPortablePriceRangeM],
      AUD: [audPortablePriceRangeY, audPortablePriceRangeM],
      GBP: [gbpPortablePriceRangeY, gbpPortablePriceRangeM],
      EUR: [eurPortablePriceRangeY, eurPortablePriceRangeM],
      theDiscount: {
        yr: "10% discount applied",
        mo: "Get 10% off on yearly plans",
      },
      theFeatureList: completeFeatureList,
      theFeatureListPricing: ["All features."],
      showTotalLabel: true,
      isRecommended: true,
      theAction: "/sign-up/",
      thePlan: "Complete-portable",
      thePlanLabel: "Complete",
      showSign: true,
      showCounter: true,
      theme: "#075695",
      popular: true,
      icon: Start,
      unitLabel: "Facility",
    },
    {
      service: "kiosks",
      sidUSD: [
        process.env.REACT_APP_STRIPE_FALITY_PRICE_KIOSKS_YEARLY_USD,
        process.env.REACT_APP_STRIPE_FALITY_PRICE_KIOSKS_MONTHLY_USD,
      ],
      sidAUD: [
        process.env.REACT_APP_STRIPE_FALITY_PRICE_KIOSKS_YEARLY_AUD,
        process.env.REACT_APP_STRIPE_FALITY_PRICE_KIOSKS_MONTHLY_AUD,
      ],
      sidGBP: [
        process.env.REACT_APP_STRIPE_FALITY_PRICE_KIOSKS_YEARLY_GBP,
        process.env.REACT_APP_STRIPE_FALITY_PRICE_KIOSKS_MONTHLY_GBP,
      ],
      sidEUR: [
        process.env.REACT_APP_STRIPE_FALITY_PRICE_KIOSKS_YEARLY_EUR,
        process.env.REACT_APP_STRIPE_FALITY_PRICE_KIOSKS_MONTHLY_EUR,
      ],
      USD: [usdKiosksPriceRangeY, usdKiosksPriceRangeM],
      AUD: [audKiosksPriceRangeY, audKiosksPriceRangeM],
      GBP: [gbpKiosksPriceRangeY, gbpKiosksPriceRangeM],
      EUR: [eurKiosksPriceRangeY, eurKiosksPriceRangeM],
      theDiscount: {
        yr: "10% discount applied",
        mo: "Get 10% off on yearly plans",
      },
      theFeatureList: completeFeatureList,
      theFeatureListPricing: ["All features."],
      showTotalLabel: true,
      isRecommended: true,
      theAction: "/sign-up/",
      thePlan: "Complete-kiosks",
      thePlanLabel: "Complete",
      showSign: true,
      showCounter: true,
      theme: "#075695",
      popular: true,
      icon: Start,
      unitLabel: "Kiosk",
    },
    {
      sidUSD: [
        process.env.REACT_APP_STRIPE_ENTERPRISE_CUSTOM,
        process.env.REACT_APP_STRIPE_ENTERPRISE_CUSTOM,
      ],
      sidAUD: [],
      sidGBP: [],
      sidEUR: [],
      AUD: ["CUSTOM", "CUSTOM"],
      GBP: ["CUSTOM", "CUSTOM"],
      EUR: ["CUSTOM", "CUSTOM"],
      USD: ["CUSTOM", "CUSTOM"],
      isRecommended: false,
      theAction: "/contact-us/",
      thePlanLabel: "Custom",
      thePlan: "Contact-us",
      buttonLabel: "CONTACT US",
      theDiscount: {
        yr: "",
        mo: "",
      },
      theFeatureList: [
        {name: "Call centers", tooltip: ''},
        {name: "Logistics & shippings", tooltip: ''},
        {name: "Any other users for Calcumate", tooltip: ''}
      ],
      theFeatureListPricing: [
        {
          name: `<p style="display: flex; align-items: flex-start;gap: 14px;align-items: center;"><img style="width:40px;margin-top:11px" src="${ContactUs}" /> <span>Please <a href="/contact-us/">contact us</a>. in relation to:</span></p>`,
          tooltip: ''
        },
      ],
      theme: "#EFF5F7",
      icon: CrownCircle,
      classes: "contact-us",
    },
  ],
  calcumateCustomPlans: [
    {
      sidUSD: [],
      sidAUD: [],
      sidGBP: [],
      sidEUR: [],
      AUD: [],
      GBP: [],
      EUR: [],
      USD: [],
      isRecommended: false,
      theAction: "",
      thePlan: "Custom",
      theDiscount: {
        yr: "",
        mo: "",
      },
      theFeatureList: [],
      theFeatureListPricing: [],
      theme: "#BFDCE6",
    },
    {
      sidUSD: [],
      sidAUD: [],
      sidGBP: [],
      sidEUR: [],
      AUD: [],
      GBP: [],
      EUR: [],
      isRecommended: false,
      theAction: "",
      thePlan: "cancelled",
      USD: [],
      theDiscount: {
        yr: "",
        mo: "",
      },
      theFeatureList: [],
      theFeatureListPricing: [],
      theme: "#BFDCE6",
    },
    {
      sidUSD: [process.env.REACT_APP_STRIPE_GENERATOR_PLAN_YEARLY_CUSTOM_1],
      sidAUD: [],
      sidGBP: [],
      sidEUR: [],
      AUD: [],
      GBP: [],
      EUR: [],
      isRecommended: true,
      theAction: "",
      showSign: true,
      thePlan: "Generator",
      USD: [637],
      theDiscount: {
        yr: "",
        mo: "",
      },
      theFeatureList: [],
      theFeatureListPricing: [],
    },
    {
      sidUSD: [, process.env.REACT_APP_STRIPE_CORE_PRICE_MONTHLY_OLD],
      sidAUD: [],
      sidGBP: [],
      sidEUR: [],
      AUD: [],
      GBP: [],
      EUR: [],
      isRecommended: true,
      theAction: "",
      showSign: true,
      thePlan: "Core (Custom)",
      USD: [, 29],
      theDiscount: {
        yr: "",
        mo: "",
      },
      theFeatureList: [],
      theFeatureListPricing: [],
    },
    {
      sidUSD: [],
      sidAUD: [],
      sidGBP: [, process.env.REACT_APP_STRIPE_AMELIE_PRICE_MONTHLY_GBP],
      sidEUR: [],
      AUD: [],
      GBP: [, 32],
      EUR: [],
      isRecommended: true,
      theAction: "",
      showSign: true,
      thePlan: "Core (Custom)",
      USD: [],
      theDiscount: {
        yr: "",
        mo: "",
      },
      theFeatureList: [],
      theFeatureListPricing: [],
    },
    //REACT_APP_STRIPE_AMELIE_PRICE_MONTHLY_GBP
    {
      sidUSD: [, process.env.REACT_APP_SQURAZ_STRIPE_CUSTOM_PRICE],
      sidAUD: [],
      sidGBP: [],
      sidEUR: [],
      AUD: [],
      GBP: [],
      EUR: [],
      isRecommended: true,
      theAction: "",
      showSign: true,
      thePlanLabel: "Calcumate Subscription",
      thePlan: "Generator",
      USD: [, 99],
      theDiscount: {
        yr: "",
        mo: "",
      },
      theFeatureList: [],
      theFeatureListPricing: [],
    },
    {
      sidUSD: [],
      sidAUD: [],
      sidGBP: [, process.env.REACT_APP_STRIPE_GUARDIAN_PRICE_MONTHLY_GBP],
      sidEUR: [],
      AUD: [],
      GBP: [],
      EUR: [],
      isRecommended: true,
      theAction: "",
      showSign: true,
      thePlanLabel: "Calcumate Subscription (£43.99 GBP every 3 months)",
      thePlan: "Generator",
      USD: [],
      theDiscount: {
        yr: "",
        mo: "",
      },
      theFeatureList: [],
      theFeatureListPricing: [],
    },
    //REACT_APP_STRIPE_STRAGELY_PRICE
    {
      sidUSD: [, process.env.REACT_APP_STRIPE_STRAGELY_PRICE],
      sidAUD: [],
      sidGBP: [],
      sidEUR: [],
      AUD: ["CUSTOM", "CUSTOM"],
      GBP: ["CUSTOM", "CUSTOM"],
      EUR: ["CUSTOM", "CUSTOM"],
      isRecommended: true,
      theAction: "",
      showSign: true,
      thePlanLabel: "Storagely.io",
      thePlan: "Storagely",
      USD: ["CUSTOM", "CUSTOM"],
      theDiscount: {
        yr: "",
        mo: "",
      },
      theFeatureList: [],
      theFeatureListPricing: [],
    },
    {
      sidUSD: [, process.env.REACT_APP_HILLS_STORAGE_PRICE],
      sidAUD: [],
      sidGBP: [],
      sidEUR: [],
      AUD: [],
      GBP: [],
      EUR: [],
      isRecommended: true,
      theAction: "",
      showSign: true,
      thePlanLabel: "Calcumate Subscription",
      thePlan: "Core",
      USD: [, 49],
      theDiscount: {
        yr: "",
        mo: "",
      },
      theFeatureList: [],
      theFeatureListPricing: [],
    },
  ],
  calcumatePlansOld: [
    {
      sidUSD: [
        process.env.REACT_APP_STRIPE_ESSENTIAL_PLAN,
        process.env.REACT_APP_STRIPE_ESSENTIAL_PLAN,
        process.env.REACT_APP_STRIPE_BASIC_PRICE_MONTHLY,
        process.env.REACT_APP_STRIPE_BASIC_PRICE_MONTHLY,
      ],
      sidAUD: [
        process.env.REACT_APP_STRIPE_ESSENTIAL_PLAN,
        process.env.REACT_APP_STRIPE_ESSENTIAL_PLAN,
        //temp solution
        process.env.REACT_APP_STRIPE_BASIC_PRICE_MONTHLY,
        process.env.REACT_APP_STRIPE_BASIC_PRICE_MONTHLY,
      ],
      sidGBP: [
        process.env.REACT_APP_STRIPE_ESSENTIAL_PLAN,
        process.env.REACT_APP_STRIPE_ESSENTIAL_PLAN,
        //temp solution
        process.env.REACT_APP_STRIPE_BASIC_PRICE_MONTHLY,
        process.env.REACT_APP_STRIPE_BASIC_PRICE_MONTHLY,
      ],
      sidEUR: [
        process.env.REACT_APP_STRIPE_ESSENTIAL_PLAN,
        process.env.REACT_APP_STRIPE_ESSENTIAL_PLAN,
        //temp solution
        process.env.REACT_APP_STRIPE_BASIC_PRICE_MONTHLY,
        process.env.REACT_APP_STRIPE_BASIC_PRICE_MONTHLY,
      ],
      isRecommended: false,
      theAction: "/sign-up?plan=limited",
      thePlan: "Limited",
      thePlanLabel: "Limited",
      buttonLabel: "free",
      USD: ["FREE", "FREE"],
      AUD: ["FREE", "FREE"],
      GBP: ["FREE", "FREE"],
      EUR: ["FREE", "FREE"],
      theDiscount: {
        yr: "",
        mo: "",
      },
      theFeatureList: [
        "Self-support only",
        "Website embeddable",
        "Upload your sizes",
        "Fully mobile-optimized",
        `Any spoken language`,
        freeItems.length + " common furniture items",
      ],
      theFeatureListPricing: [
        '<p style="margin-bottom: 47px">Limited features. Only for single-facility self storage or container storage operators with very basic requirements.</p>',
      ],
      theme: "white",
      icon: Rainbow,
      classes: "limited",
    },
    {
      sidUSD: [
        process.env.REACT_APP_STRIPE_CORE_PRICE_YEARLY,
        process.env.REACT_APP_STRIPE_CORE_PRICE_MONTHLY,
      ],
      sidAUD: [
        process.env.REACT_APP_STRIPE_CORE_PRICE_YEARLY_AUD,
        process.env.REACT_APP_STRIPE_CORE_PRICE_MONTHLY_AUD,
      ],
      sidGBP: [
        process.env.REACT_APP_STRIPE_CORE_PRICE_YEARLY_GBP,
        process.env.REACT_APP_STRIPE_CORE_PRICE_MONTHLY_GBP,
      ],
      sidEUR: [
        process.env.REACT_APP_STRIPE_CORE_PRICE_YEARLY_EUR,
        process.env.REACT_APP_STRIPE_CORE_PRICE_MONTHLY_EUR,
      ],
      isRecommended: false,
      theAction: "/sign-up/",
      thePlan: "Core",
      showSign: true,
      USD: [421, 39],
      AUD: [611, 57],
      GBP: [351, 32],
      EUR: [412, 38],
      theDiscount: {
        yr: "10% discount applied",
        mo: "Get 10% off on yearly plans",
      },
      theFeatureList: [
        "Your company branding",
        "Booking flow connections",
        "130+ furniture items",
        "Preset lists for fast results",
        "Up to 3 calculators",
        "<strong>30-day free trial</strong>",
      ],
      theFeatureListPricing: ["Tailored branding"],
      theme: "#EFF5F7",
      popular: true,
    },
    {
      sidUSD: [
        process.env.REACT_APP_STRIPE_GENERATOR_PLAN_YEARLY,
        process.env.REACT_APP_STRIPE_GENERATOR_PLAN_MONTHLY,
      ],
      sidAUD: [
        process.env.REACT_APP_STRIPE_GENERATOR_PLAN_YEARLY_AUD,
        process.env.REACT_APP_STRIPE_GENERATOR_PLAN_MONTHLY_AUD,
      ],
      sidGBP: [
        process.env.REACT_APP_STRIPE_GENERATOR_PLAN_YEARLY_GBP,
        process.env.REACT_APP_STRIPE_GENERATOR_PLAN_MONTHLY_GBP,
      ],
      sidEUR: [
        process.env.REACT_APP_STRIPE_GENERATOR_PLAN_YEARLY_EUR,
        process.env.REACT_APP_STRIPE_GENERATOR_PLAN_MONTHLY_EUR,
      ],
      isRecommended: false,
      theAction: "/sign-up/",
      thePlan: "Generator",
      USD: [961, 89],
      AUD: [1394, 129],
      GBP: [800, 74],
      EUR: [939, 87],
      showSign: true,
      theDiscount: {
        yr: "10% discount applied",
        mo: "Get 10% off on yearly plans",
      },
      theFeatureList: [
        "Bespoke lead capture form",
        "Usage reporting",
        "Custom & searchable items",
        "Early access to new features",
        "Account executive",
        "Up to 10 calculators",
        "<strong>30-day free trial</strong>",
      ],
      theFeatureListPricing: ["Instant, engaged leads"],
      theme: "#BFDCE6",
    },
    {
      sidUSD: [
        process.env.REACT_APP_STRIPE_STARTER_PRICE,
        process.env.REACT_APP_STRIPE_STARTER_PLAN,
      ],
      sidAUD: [],
      sidGBP: [],
      sidEUR: [],
      showSign: true,
      isRecommended: false,
      theAction: "/sign-up/",
      thePlan: "Starter",
      USD: [313, 29],
      theDiscount: {
        yr: "10% discount applied",
        mo: "Get 10% off on yearly plans",
      },
      theFeatureList: [
        "1 - 5 Storage facilities" /*, "Free setup", "Custom design", "Custom units", "Up to 20 items selected", "Custom languages", "Metric or Imperial setup"*/,
      ],
      theFeatureListPricing: ["1 - 5"],
    },
    {
      sidUSD: [
        process.env.REACT_APP_STRIPE_PROFESSIONAL_PRICE,
        process.env.REACT_APP_STRIPE_PROFESSIONAL_PLAN,
      ],
      sidAUD: [],
      sidGBP: [],
      sidEUR: [],
      showSign: true,
      isRecommended: true,
      theAction: "/sign-up?plan=professional",
      thePlan: "Professional",
      USD: [529, 49],
      theDiscount: {
        yr: "10% discount applied",
        mo: "Get 10% off on yearly plans",
      },
      theFeatureList: [
        "5 - 15 Storage facilities" /*, "Free setup", "Custom design", "Custom units", "Up to 20 items selected", "Custom languages", "Metric or Imperial setup"*/,
      ],
      theFeatureListPricing: ["5 - 15"],
    },
    {
      sidUSD: [
        process.env.REACT_APP_STRIPE_ENTERPRISE_PRICE,
        process.env.REACT_APP_STRIPE_ENTERPRISE_PLAN,
      ],
      sidAUD: [],
      sidGBP: [],
      sidEUR: [],
      isRecommended: false,
      theAction: "/sign-up?plan=enterprise",
      thePlan: "Enterprise",
      USD: [1069, 99],
      AUD: [],
      GBP: [],
      EUR: [],
      showSign: true,
      theDiscount: {
        yr: "10% discount applied",
        mo: "Get 10% off on yearly plans",
      },
      theFeatureList: [
        "15+ Storage facilities" /*, "Free setup", "Custom design", "Custom units", "Up to 20 items selected", "Custom languages", "Metric or Imperial setup"*/,
      ],
      theFeatureListPricing: ["15+"],
    },
    {
      sidUSD: [
        process.env.REACT_APP_STRIPE_BASIC_PRICE,
        process.env.REACT_APP_STRIPE_BASIC_PLAN,
      ],
      sidAUD: [],
      sidGBP: [],
      sidEUR: [],
      isRecommended: false,
      theAction: "/sign-up?plan=basic",
      thePlan: "Basic",
      USD: ["FREE", "FREE"],
      AUD: ["FREE", "FREE"],
      GBP: ["FREE", "FREE"],
      EUR: ["FREE", "FREE"],
      theDiscount: {
        yr: "",
        mo: "",
      },
      theFeatureList: ["No Thrills\n\r No Fees"],
      theFeatureListPricing: ["No Thrills \n\r No Fees"],
    },
    {
      sidUSD: [, process.env.REACT_APP_STRIPE_BASIC_PRICE_MONTHLY],
      sidAUD: [],
      sidGBP: [],
      sidEUR: [],
      isRecommended: false,
      theAction: "/sign-up?plan=basic",
      thePlan: "Basic",
      USD: ["FREE", "FREE"],
      AUD: ["FREE", "FREE"],
      GBP: ["FREE", "FREE"],
      EUR: ["FREE", "FREE"],
      theDiscount: {
        yr: "",
        mo: "",
      },
      theFeatureList: ["No Thrills\n\r No Fees"],
      theFeatureListPricing: ["No Thrills \n\r No Fees"],
    },
    {
      sidUSD: [
        process.env.REACT_APP_STRIPE_GENERATOR_PLAN_YEARLY_OLD,
        process.env.REACT_APP_STRIPE_GENERATOR_PLAN_MONTHLY_OLD,
      ],
      sidAUD: [],
      sidGBP: [],
      sidEUR: [],
      isRecommended: false,
      theAction: "/sign-up/",
      thePlan: "Generator",
      USD: [961, 89],
      AUD: [1394, 129],
      GBP: [800, 74],
      EUR: [939, 87],
      showSign: true,
      theDiscount: {
        yr: "10% discount applied",
        mo: "Get 10% off on yearly plans",
      },
      theFeatureList: [
        "Bespoke lead capture form",
        "Usage reporting",
        "Custom & searchable items",
        "Early access to new features",
        "Account executive",
        "Up to 10 calculators",
        "<strong>30-day free trial</strong>",
      ],
      theFeatureListPricing: ["Instant, engaged leads"],
      theme: "#BFDCE6",
    },
  ],
  subscriptionFeatures: [
    "Free setup & a 30-day free trial",
    "Lead generating capabilities",
    "Your own branding & colours",
    "Our database of 90 furniture items",
    "10% off annual subscriptions",
    "No commitments, cancel anytime",
  ],
  adminNavigationMobile: false,
  integrationDataOptions: {
    integrationPanelsFS: false,
  },
  integrationData: {
    // this is the record holding data for a new integration
    integrationName: "My integration",
    integrationHideIsometricGrid: false,
    integrationSecPanel: false,
    integrationThirdPanel: false,
    integrationSelectedItemsIntoURL: [],
    integrationStepperStep: 0,
    integrationLoadingCustomerEmails: [],
    integrationLoadDetailsEmails: [],
    integrationStepperSteps: [
      "Options",
      "Units",
      "Categories",
      "Presets",
      "Design",
      "Details",
      "Confirm",
    ],
    integrationDomain: "",
    domainUrlValid: true,
    domainUrlValid2: true,
    integrationActiveLanguage: "en",
    integrationItemsListMode: "slider",
    integrationAmountMultipleUnit: 3,
    integrationDisableMultipleResults: true,
    integrationAllowCopy: false,
    showResultUnitDetails: false,
    integrationShowItemWeight: false,
    integrationHideCopyright: false,
    integrationLoadDetailsActive: false,
    integrationAddCustomItems: false,
    integrationCTARedirection: false,
    showLoadingPlanButton: false,
    integrationAllowAnimationControl: false,
    integrationCustomUnitImageEnabled: false,
    integrationCtaShouldIncludeUnitQuantity: false,
    integrationPrimaryText: "",
    integrationSecondaryText: "",
    integrationCategoryButtonBorderColor: "#005695",
    integrationItemTextColour: "#3c4858",
    isEnabledNearestFacility: false,
    integrationLanguages: [
      {
        language: "Arabic",
        value: "ar",
      },
      {
        language: "Catalan",
        value: "ct",
      },
      {
        language: "Danish",
        value: "da",
      },
      {
        language: "Dutch",
        value: "nl",
      },
      {
        language: "English",
        value: "en",
      },
      {
        language: "Estonian",
        value: "ee",
      },
      {
        language: "Finnish",
        value: "fi",
      },
      {
        language: "French",
        value: "fr",
      },
      {
        language: "German",
        value: "de",
      },
      {
        language: "Hebrew",
        value: "he",
      },
      {
        language: "Hungarian",
        value: "hu",
      },
      {
        language: "Japanese",
        value: "ja",
      },
      {
        language: "Korean",
        value: "ko",
      },
      {
        language: "Lithuanian",
        value: "lt",
      },
      {
        language: "Norwegian",
        value: "no",
      },
      {
        language: "Polish",
        value: "pl",
      },
      {
        language: "Portuguese",
        value: "pt",
      },
      {
        language: "Romanian",
        value: "ro",
      },
      {
        language: "Spanish",
        value: "es",
      },
      {
        language: "Swedish",
        value: "sw",
      },
      {
        language: "Traditional Chinese",
        value: "ch",
      },
      {
        language: "Turkish",
        value: "tr",
      },
      {
        language: "Vietnamese",
        value: "vi",
      },
    ],
    integrationStorageType: "self-storage",
    integrationMeasurement: "m",
    integrationMeasurement2: ["cm", "in"],
    integrationCtaURL: "",
    ctaUrlValid: true,
    integrationIncQty: false,
    integrationIncID: false,
    integrationIncUsedVolume: false,
    integrationIncVolume: false,
    integrationCTALabel: "GET QUOTE",
    integrationCTAType: false,
    integrationCTATarget: "_self",
    integrationIsNewHelper: false,
    integrationEditing: 0,
    newIntegration: true,
    integrationNewUnit: {
      ID: 0,
      unitName: "",
      unitWidth: "",
      unitHeight: "",
      unitDepth: "",
    },
    integrationUnits: defaultUnits["self-storage"],
    integrationNewCategory: {
      ID: 0,
      catName: "",
      itemsAssigned: [],
    },
    integrationCategories: [
      {
        ID: 0,
        catName: "Kitchen",
        itemsAssigned: [0, 1],
      },
    ],
    integrationNewPreset: {
      ID: 0,
      preName: "",
      itemsAssigned: [],
      qtyAssigned: [],
    },
    integrationPresets: [
      {
        ID: 0,
        preName: "1 Bedroom",
        itemsAssigned: [
          0,
          3,
          5,
          11,
          12,
          17,
          22,
          24,
          25,
          29,
          30,
          33,
          35,
          38,
          45,
          58,
          63,
          67,
          76,
          78,
          79,
        ],
        qtyAssigned: [
          1,
          3,
          1,
          1,
          1,
          1,
          1,
          1,
          1,
          1,
          4,
          4,
          1,
          1,
          1,
          1,
          1,
          8,
          1,
          2,
          1,
        ],
        items: [
          { id: "MIRROR" },
          { id: "CHAIR-LIVINGROOM-13" },
          { id: "DININGTABLE46-LIVINGROOM-11" },
          { id: "PAINTING-LIVINGROOM-22" },
          { id: "RUG-LIVINGROOM-21" },
          { id: "BEDSIDETABLE-BEDROOM-5" },
          { id: "2SEATSOFA-LIVINGROOM-15" },
          { id: "SMALLTABLE-LIVINGROOM-9" },
          { id: "DESK-BEDROOM-8" },
          { id: "LAMP-DESK" },
          { id: "MEDIUMBOX-OTHER-30" },
          { id: "LARGEBOX-OTHER-31" },
          { id: "COFFEETABLE-LIVINGROOM-10" },
          { id: "BEDBASEENSEMBLE-BEDROOM-3" },
          { id: "DRAWERS-BEDROOM-6" },
          { id: "QUEENKINGMATTRESS-BEDROOM-2" },
          { id: "ARMCHAIR-LIVINGROOM-14" },
          { id: "SMALLBOX-OTHER-29" },
          { id: "TVUNIT-LIVINGROOM-18" },
          { id: "STOOL-KITCHEN-28" },
          { id: "TV-LIVINGROOM-17" },
        ],
      },
      {
        ID: 1,
        preName: "2 Bedroom",
        itemsAssigned: [
          0,
          3,
          5,
          11,
          12,
          17,
          22,
          24,
          25,
          29,
          30,
          33,
          35,
          38,
          45,
          53,
          58,
          59,
          63,
          67,
          76,
          78,
          79,
        ],
        qtyAssigned: [
          1,
          5,
          1,
          2,
          1,
          2,
          1,
          1,
          2,
          2,
          6,
          6,
          1,
          2,
          2,
          1,
          2,
          1,
          1,
          8,
          1,
          2,
          1,
        ],
        items: [
          { id: "MIRROR" },
          { id: "CHAIR-LIVINGROOM-13" },
          { id: "DININGTABLE46-LIVINGROOM-11" },
          { id: "PAINTING-LIVINGROOM-22" },
          { id: "RUG-LIVINGROOM-21" },
          { id: "BEDSIDETABLE-BEDROOM-5" },
          { id: "2SEATSOFA-LIVINGROOM-15" },
          { id: "SMALLTABLE-LIVINGROOM-9" },
          { id: "DESK-BEDROOM-8" },
          { id: "LAMP-DESK" },
          { id: "MEDIUMBOX-OTHER-30" },
          { id: "LARGEBOX-OTHER-31" },
          { id: "COFFEETABLE-LIVINGROOM-10" },
          { id: "BEDBASEENSEMBLE-BEDROOM-3" },
          { id: "DRAWERS-BEDROOM-6" },
          { id: "LAMP-FLOOR" },
          { id: "QUEENKINGMATTRESS-BEDROOM-2" },
          { id: "BOOKCASE-LIVINGROOM-20" },
          { id: "ARMCHAIR-LIVINGROOM-14" },
          { id: "SMALLBOX-OTHER-29" },
          { id: "TVUNIT-LIVINGROOM-18" },
          { id: "STOOL-KITCHEN-28" },
          { id: "TV-LIVINGROOM-17" },
        ],
      },
      {
        ID: 2,
        preName: "3 Bedroom",
        itemsAssigned: [
          0,
          1,
          3,
          11,
          12,
          14,
          17,
          21,
          22,
          24,
          25,
          28,
          29,
          30,
          35,
          31,
          33,
          32,
          38,
          45,
          49,
          53,
          58,
          59,
          63,
          67,
          76,
          78,
          79,
        ], //
        qtyAssigned: [
          2,
          1,
          5,
          4,
          2,
          1,
          4,
          1,
          1,
          2,
          2,
          1,
          3,
          8,
          1,
          1,
          8,
          1,
          3,
          3,
          1,
          2,
          3,
          2,
          2,
          10,
          1,
          2,
          2,
        ],
        items: [
          { id: "MIRROR" },
          { id: "WARDROBE-TWO-DOOR" },
          { id: "CHAIR-LIVINGROOM-13" },
          { id: "PAINTING-LIVINGROOM-22" },
          { id: "RUG-LIVINGROOM-21" },
          { id: "DININGTABLE8-LIVINGROOM-12" },
          { id: "BEDSIDETABLE-BEDROOM-5" },
          { id: "BUFFETT-LIVINGROOM-19" },
          { id: "2SEATSOFA-LIVINGROOM-15" },
          { id: "SMALLTABLE-LIVINGROOM-9" },
          { id: "DESK-BEDROOM-8" },
          { id: "WARDROBE-ONE-DOOR" },
          {
            id: "LAMP-DESK",
          },
          {
            id: "MEDIUMBOX-OTHER-30",
          },
          {
            id: "COFFEETABLE-LIVINGROOM-10",
          },
          {
            id: "WELSH-DRESER",
          },
          {
            id: "LARGEBOX-OTHER-31",
          },
          {
            id: "3SEATSOFA-LIVINGROOM-16",
          },
          {
            id: "BEDBASEENSEMBLE-BEDROOM-3",
          },
          {
            id: "DRAWERS-BEDROOM-6",
          },
          {
            id: "NEST-OF-TABLES",
          },
          {
            id: "LAMP-FLOOR",
          },
          {
            id: "QUEENKINGMATTRESS-BEDROOM-2",
          },
          {
            id: "BOOKCASE-LIVINGROOM-20",
          },
          {
            id: "ARMCHAIR-LIVINGROOM-14",
          },
          {
            id: "SMALLBOX-OTHER-29",
          },
          {
            id: "TVUNIT-LIVINGROOM-18",
          },
          {
            id: "STOOL-KITCHEN-28",
          },
          {
            id: "TV-LIVINGROOM-17",
          },
        ],
      },
      {
        ID: 3,
        preName: "4 Bedroom",
        itemsAssigned: [
          0,
          1,
          3,
          11,
          12,
          14,
          17,
          21,
          22,
          24,
          25,
          28,
          29,
          30,
          35,
          31,
          33,
          32,
          38,
          43,
          45,
          49,
          53,
          58,
          59,
          63,
          67,
          76,
          78,
          79,
        ],
        qtyAssigned: [
          3,
          2,
          6,
          5,
          3,
          1,
          6,
          1,
          1,
          2,
          2,
          1,
          4,
          10,
          1,
          1,
          10,
          1,
          4,
          1,
          4,
          1,
          3,
          4,
          3,
          2,
          12,
          1,
          4,
          2,
        ],
        items: [
          {
            id: "MIRROR",
          },
          {
            id: "WARDROBE-TWO-DOOR",
          },
          {
            id: "CHAIR-LIVINGROOM-13",
          },
          {
            id: "PAINTING-LIVINGROOM-22",
          },
          {
            id: "RUG-LIVINGROOM-21",
          },
          {
            id: "DININGTABLE8-LIVINGROOM-12",
          },
          {
            id: "BEDSIDETABLE-BEDROOM-5",
          },
          {
            id: "BUFFETT-LIVINGROOM-19",
          },
          {
            id: "2SEATSOFA-LIVINGROOM-15",
          },
          {
            id: "SMALLTABLE-LIVINGROOM-9",
          },
          {
            id: "DESK-BEDROOM-8",
          },
          {
            id: "WARDROBE-ONE-DOOR",
          },
          {
            id: "LAMP-DESK",
          },
          {
            id: "MEDIUMBOX-OTHER-30",
          },
          {
            id: "COFFEETABLE-LIVINGROOM-10",
          },
          {
            id: "WELSH-DRESER",
          },
          {
            id: "LARGEBOX-OTHER-31",
          },
          {
            id: "3SEATSOFA-LIVINGROOM-16",
          },
          {
            id: "BEDBASEENSEMBLE-BEDROOM-3",
          },
          {
            id: "PIANO",
          },
          {
            id: "DRAWERS-BEDROOM-6",
          },
          {
            id: "NEST-OF-TABLES",
          },
          {
            id: "LAMP-FLOOR",
          },
          {
            id: "QUEENKINGMATTRESS-BEDROOM-2",
          },
          {
            id: "BOOKCASE-LIVINGROOM-20",
          },
          {
            id: "ARMCHAIR-LIVINGROOM-14",
          },
          {
            id: "SMALLBOX-OTHER-29",
          },
          {
            id: "TVUNIT-LIVINGROOM-18",
          },
          {
            id: "STOOL-KITCHEN-28",
          },
          {
            id: "TV-LIVINGROOM-17",
          },
        ],
      },
      {
        ID: 4,
        preName: "5+ Bedroom",
        itemsAssigned: [
          0,
          1,
          3,
          11,
          12,
          14,
          17,
          21,
          22,
          24,
          25,
          28,
          29,
          30,
          35,
          31,
          33,
          32,
          38,
          43,
          45,
          49,
          53,
          58,
          59,
          63,
          67,
          76,
          78,
          79,
        ],
        qtyAssigned: [
          5,
          3,
          8,
          6,
          4,
          1,
          8,
          1,
          1,
          3,
          3,
          1,
          6,
          12,
          1,
          1,
          12,
          2,
          5,
          1,
          5,
          2,
          4,
          5,
          3,
          4,
          14,
          2,
          4,
          3,
        ],
        items: [
          { id: "MIRROR" },
          {
            id: "WARDROBE-TWO-DOOR",
          },
          {
            id: "CHAIR-LIVINGROOM-13",
          },
          {
            id: "PAINTING-LIVINGROOM-22",
          },
          {
            id: "RUG-LIVINGROOM-21",
          },
          {
            id: "DININGTABLE8-LIVINGROOM-12",
          },
          {
            id: "BEDSIDETABLE-BEDROOM-5",
          },
          {
            id: "BUFFETT-LIVINGROOM-19",
          },
          {
            id: "2SEATSOFA-LIVINGROOM-15",
          },
          {
            id: "SMALLTABLE-LIVINGROOM-9",
          },
          {
            id: "DESK-BEDROOM-8",
          },
          {
            id: "WARDROBE-ONE-DOOR",
          },
          {
            id: "LAMP-DESK",
          },
          {
            id: "MEDIUMBOX-OTHER-30",
          },
          {
            id: "COFFEETABLE-LIVINGROOM-10",
          },
          {
            id: "WELSH-DRESER",
          },
          {
            id: "LARGEBOX-OTHER-31",
          },
          {
            id: "3SEATSOFA-LIVINGROOM-16",
          },
          {
            id: "BEDBASEENSEMBLE-BEDROOM-3",
          },
          {
            id: "PIANO",
          },
          {
            id: "DRAWERS-BEDROOM-6",
          },
          {
            id: "NEST-OF-TABLES",
          },
          {
            id: "LAMP-FLOOR",
          },
          {
            id: "QUEENKINGMATTRESS-BEDROOM-2",
          },
          {
            id: "BOOKCASE-LIVINGROOM-20",
          },
          {
            id: "ARMCHAIR-LIVINGROOM-14",
          },
          {
            id: "SMALLBOX-OTHER-29",
          },
          {
            id: "TVUNIT-LIVINGROOM-18",
          },
          {
            id: "STOOL-KITCHEN-28",
          },
          {
            id: "TV-LIVINGROOM-17",
          },
        ],
      },
    ],
    itemLibrary: [
      {
        ID: 0,
        defaultName: "Item 1",
        de: "Bettkasten",
        customName: "",
        itemThumbnail:
          "https://calcumate.co/wp-content/themes/calcumate-2019/dist/images/slider-furniture-icons/queen-mattress-scale-240.png",
        dimension: ["1.1", "2.1"],
        w: ["20", "30"],
        h: ["20", "30"],
        d: ["20", "30"],
        group: ["kitchen", "bathroom"],
      },
      {
        ID: 1,
        defaultName: "Item 2",
        de: "Bettkasten",
        customName: "",
        itemThumbnail:
          "https://calcumate.co/wp-content/themes/calcumate-2019/dist/images/slider-furniture-icons/sofa-2-scale.png",
        dimension: ["1.1", "2.1"],
        w: ["20", "30"],
        h: ["20", "30"],
        d: ["20", "30"],
        group: ["kitchen", "bathroom"],
      },
      {
        ID: 2,
        defaultName: "Item 3",
        de: "Bettkasten",
        customName: "",
        itemThumbnail:
          "https://calcumate.co/wp-content/themes/calcumate-2019/dist/images/slider-furniture-icons/bookcase-scale.png",
        dimension: ["1.1", "2.1"],
        w: ["20", "30"],
        h: ["20", "30"],
        d: ["20", "30"],
        group: ["kitchen", "bathroom"],
      },
    ],
    integrationActiveFont: "My website font",
    integrationFontFamily: [
      "My website font",
      "Roboto",
      "Arial",
      "Times New Roman",
    ],
    integrationPrimaryColour: "#005695",
    integrationPrimaryTextColor: "#000000",
    integrationUnitColor: "#005695",
    integrationSecondaryColour: "#A380DB",
    integrationActiveBgStyle: "gradient",
    integrationGradientOne: "#A2C6DF",
    integrationGradientTwo: "#EFF5F7",
    integrationButtonColour: "#A380DB",
    integrationButtonTextColour: "#FFFFFF",
    integrationDisplaySearch: false,
    integrationPresetBackground: null,
    integrationPresetColor: null,
    integrationLoadGenerationForm: [
      {
        locked: false,
        type: "text",
        label: "Full Name",
        name: "name",
      },
      {
        locked: true,
        type: "text",
        label: "Email",
        name: "email",
        validation: true,
      },
      {
        locked: false,
        type: "phone",
        label: "Phone number",
        name: "phone",
        validation: true,
      },
      {
        locked: false,
        type: "text",
        label: "Additional notes",
        name: "additional-info",
      },
    ],
    integrationLoadingPlanFields: [
      {
        locked: false,
        type: "text",
        label: "Full Name",
        name: "name",
      },
      {
        locked: true,
        type: "text",
        label: "Email",
        name: "email",
        validation: true,
      },
      {
        locked: false,
        type: "phone",
        label: "Phone number",
        name: "phone",
        validation: true,
      },
    ],
  },
};

export default InitState;
