import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  Typography,
  DialogActions,
  Button,
} from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";

// styles
import adminStyle from "assets/jss/material-dashboard-react/layouts/adminStyle";
const useStyles = makeStyles(adminStyle);

export default ({
  openDialog,
  setOpenDialog,
  text,
}) => {
  const classes = useStyles();

  return (
    <Dialog
      open={openDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Typography style={{fontWeight: 'unset'}} variant="h5" component="p" className={classes.textMarginBottom}>{text}</Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button size="large" onClick={() => { setOpenDialog(false) }} color="primary">OK</Button>
      </DialogActions>
    </Dialog>
  );
}